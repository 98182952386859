import { API_URL } from 'shared/api/Constants';
import { apiInterceptor } from './axios';


export const setChatConversationStatusAPI = (params: any) => {
  return apiInterceptor.post(`${API_URL.GET_CHARTID_CONVERSATION_STATUS}`, params).then((response: any) => {
    return response;
  });
};

export const getChatConversationStatusAPI = (params: any) => {
  return apiInterceptor.get(`${API_URL.GET_CONVERSATION_STATUS}/${params}`).then((response: any) => {
    return response;
  });
};

export const getChartIdAPI = (id: any) => {
  return apiInterceptor.get(`${API_URL.GET_CHARTID}/${id}`).then((response: any) => {
    return response;
  });
};

export const getVMANamesAPI = () =>{
  return apiInterceptor.get(`${API_URL.GET_VMA_NAMES}`).then((response:any) =>{
    return response;
  });
};

export const createTaskAPI = (params: any) => {
  return apiInterceptor.post(`${API_URL.CREATE_TASK}`, params).then((response: any) => {
    return response;
  });
};

export const getTaskListAPI = (data:any) =>{
  return apiInterceptor.get(`${API_URL.GET_TASK_LIST}/${data.id}/${data.listType}`).then((response:any) =>{
    return response;
  });
};

export const getTaskCountAPI = () =>{
  return apiInterceptor.get(`${API_URL.GET_TASK_COUNT}`).then((response:any) =>{
    return response;
  });
};


export const getPatientTaskListAPI = (id:any) =>{
  return apiInterceptor.get(`${API_URL.GET_PATIENT_TASK_LIST}/${id}`).then((response:any) =>{
    return response;
  });
};

export const getUnassignedConversationsAPI = () =>{
  return apiInterceptor.get(`${API_URL.GET_UNASSIGNED_CONVERSATION}`).then((response:any) =>{
    return response;
  });
};

export const getAssignedConversationsAPI = () =>{
  return apiInterceptor.get(`${API_URL.GET_ASSIGNED_CONVERSATION}`).then((response:any) =>{
    return response;
  });
};
export const getConversationGroupDetailsAPI=(id:number)=>{
  return apiInterceptor.get(`${API_URL.GET_GROUP_DETAILS}/${id}`).then((response:any) =>{
    return response;
  });
};

export const assignConversationToSelfAPI=(id:any)=>{
  return apiInterceptor.post(`${API_URL.ASSIGN_CONVERSATION_TO_SELF}/${id}`).then((response:any) =>{
    return response;
  });
};

export const assignConversationToOthersAPI=(groupId:any,id:number)=>{
  return apiInterceptor.post(`${API_URL.ASSIGN_CONVERSATION_TO_OTHER}/${groupId}/vma/${id}`).then((response:any) =>{
    return response;
  });
};

export const joinConversationAPI=(groupId:any)=>{
  return apiInterceptor.post(`${API_URL.JOIN_CONVERSATION}/${groupId}`).then((response:any) =>{
    return response;
  });
};
export const getConversationCountAPI=()=>{
  return apiInterceptor.get(`${API_URL.GET_CONVERSATION_COUNT}`).then((response:any) =>{
    return response;
  });
};

export const getVMAStatusAPI=()=>{
  return apiInterceptor.get(`${API_URL.GET_VMA_STATUS_COUNT}`).then((response:any) =>{
    return response;
  });
};

export const getPatientNamesAPI=(groupId:string)=>{
  return apiInterceptor.get(`${API_URL.GET_PATIENT_NAMES}/${groupId}`).then((response:any)=> {
    return response;
  });
};