import { useEffect, useState } from 'react';
import { getPatientNames } from 'store/slices/secureMessageSlice';
import { useAppDispatch } from 'store/hooks';
import { Grid, Typography, Badge } from '@mui/material';


const ConversationListView = (props:any) => {
  const [guardianName, setGuardianName] = useState<string>('');
  const [patientId, setPatientId] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [patientName, setPatientName] = useState<string>();
  const [userId, setUserId] = useState<string>('');
  const dispatch = useAppDispatch();  

  const fetchPatientName = async () => {
    try {
      dispatch(getPatientNames(props?.conversationId))
        .unwrap()
        .then((response:any) => {
          if(response.status === 200){
            setGuardianName(response?.data?.name);
            setPatientId(response?.data?.patientId);
            setPatientName(response?.data?.patientName);
            setUserId(response?.data?.userId);
          }
        });
    } catch (error) {
      setGuardianName('Unknown');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPatientName();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.conversationId]);

  return (
    <div>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <>{props?.patientName === 'internal' ? '' :
          <Grid container>
            {guardianName !== null && guardianName !== '' ?
              <>
                <Grid item
                  xs={1.8}
                  sx={{
                    borderRadius: '50%', background: '#FFECC0',
                    color: '#000', padding: '10px',
                    fontSize: '14px', fontWeight: 700,
                    height: '36px', width: '36px',
                    cursor: 'pointer'
                  }}>
                  {guardianName.split(' ')[0]?.charAt(0)?.toUpperCase()}
                  {guardianName.split(' ')[1]?.charAt(0)?.toUpperCase()}
                </Grid>
                <Grid item xs={9} sx={{ padding: '10px', textTransform: 'capitalize' }}>
                  <Grid container>
                    <Grid item md={10}>
                      <Typography sx={{ fontSize: '14px', fontWeight: 700, cursor: 'pointer' }}
                        onClick={() => props?.handlePatientClick(patientId,patientName, guardianName, userId)}>
                        {guardianName}
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <Badge badgeContent={props?.unreadMessageCount}
                        sx={{
                          '& .MuiBadge-badge': {
                            color: '#fff',
                            backgroundColor: '#FFC44D!important',
                            marginLeft: '20px'
                          }
                        }}>
                      </Badge>
                    </Grid>
                  </Grid>
                </Grid>
              </> : 'No Messages'}
          </Grid>}
        </>
      )}
    </div>
  );
};

export default ConversationListView;

