import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IScheduleAppointment } from '../interfaces/IUserDetails';
import {
  getAppointmentDetailsAPI,
  scheduleAppointmentAPI,
  getAppointmentConfirmationDetailsAPI,
  getAppointmentDetailsAsPerPatientId,
  getCheckInDetailsAPI,
  createPatientAPI, getAddCardLinkAPI,
  payAmountAPI
} from 'services/appointmentServices';
import { handleMessage } from 'shared/api/ErrorMessages';

interface Error {
  data: any;
}

export const scheduleAppointmentDetails = createAsyncThunk(
  'SCHEDULE_APPOINTMENT',
  async (data: IScheduleAppointment, { rejectWithValue }: any) => {
    try {
      const response = await scheduleAppointmentAPI(data);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const getScheduleAppointmentDetails = createAsyncThunk(
  'GET_APPOINTMENT_DETAILS',
  async (patientId:number,{ rejectWithValue }: any) => {
    try {
      const response = await getAppointmentDetailsAPI(patientId);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);


export const getAppointmentConfirmationDetails = createAsyncThunk(
  'GET_APPOINTMENT_DETAILS',
  async (patientId:number, { rejectWithValue }: any) => {
    try {
      const response = await getAppointmentConfirmationDetailsAPI(patientId);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const getPreferredTimeDeails = createAsyncThunk(
  'GET_PREFERRED_TIME_DETAILS',
  async (patientId: number, { rejectWithValue }: any) => {
    try {
      const response = await getAppointmentDetailsAsPerPatientId(patientId);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const getCheckInDetails = createAsyncThunk(
  'CHECK_IN_DETAILS',
  async (data:any, { rejectWithValue }: any) => {
    try {
      const response = await getCheckInDetailsAPI(data);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);


export const createPatient = createAsyncThunk(
  'CREATE_PATIENT',
  async (id:any, { rejectWithValue }: any) => {
    try {
      const response = await createPatientAPI(id);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const getAddCardLink = createAsyncThunk(
  'GET_STRIPE_LINK',
  async ({ rejectWithValue }: any) => {
    try {
      const response = await getAddCardLinkAPI();
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const payAmount = createAsyncThunk(
  'PAY_AMOUNT',
  async (id:any, { rejectWithValue }: any) => {
    try {
      const response = await payAmountAPI(id);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

interface Loader {
  isScheduleAppointmentRequested: boolean;
  isScheduleAppointmentFetched: boolean;
  isAppointmentStatusFetched: boolean;
}

interface StateI {
  loaders: Loader;
  error: undefined;
}

const initialState = {
  loaders: {
    isScheduleAppointmentRequested: false,
    isScheduleAppointmentFetched: false,
    isAppointmentStatusFetched: false,
    isAppointmentConfirmationFetched: false
  },
  error: undefined,
};

const appointmentSlice = createSlice({
  name: 'appointment',
  initialState,
  reducers: {
    clearError(state: StateI) {
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(scheduleAppointmentDetails.pending, (state: StateI) => {
      state.loaders.isScheduleAppointmentRequested = true;
    });
    builder.addCase(scheduleAppointmentDetails.fulfilled, (state: StateI, action: any) => {
      state.loaders.isScheduleAppointmentRequested = false;
    });
    builder.addCase(scheduleAppointmentDetails.rejected, (state: StateI) => {
      state.loaders.isScheduleAppointmentRequested = false;
    });
    builder.addCase(getScheduleAppointmentDetails.pending, (state: StateI) => {
      state.loaders.isScheduleAppointmentFetched = true;
    });
    builder.addCase(getScheduleAppointmentDetails.fulfilled, (state: StateI, action: any) => {
      state.loaders.isScheduleAppointmentFetched = false;
    });
    builder.addCase(getScheduleAppointmentDetails.rejected, (state: StateI) => {
      state.loaders.isScheduleAppointmentFetched = false;
    });
    builder.addCase(getPreferredTimeDeails.pending, (state: StateI) => {
      state.loaders.isAppointmentStatusFetched = true;
    });
    builder.addCase(getPreferredTimeDeails.fulfilled, (state: StateI, action: any) => {
      state.loaders.isAppointmentStatusFetched = false;
    });
    builder.addCase(getPreferredTimeDeails.rejected, (state: StateI) => {
      state.loaders.isAppointmentStatusFetched = false;
    });
  },
});

export default appointmentSlice.reducer;
