import React, { useContext, useEffect, useState } from 'react';
import {
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListProps,
  ListSubheader,
  Stack,
  styled,
} from '@mui/material';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import { useLocation } from 'react-router-dom';
import Loader from 'components/Loader';
import CircularProgress from '@mui/material/CircularProgress';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getDocumentList, downloadDocument } from 'store/slices/documentSlice';
import { Page } from 'components/Page';
import COLORS from 'theme/colors';
import { ROUTE } from 'route/RouteEnums';
import { toast } from 'react-toastify';
import { AuthContext } from 'utils/AuthContext';

const Documents = (props: any) => {

  const [filesData, setFilesData] = useState<any>();
  const [loader, setLoader] = useState<boolean>(false);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const storeResponse: any = useAppSelector((state) => state.documentList);
  const {user} = useContext(AuthContext);
  const StyleList = styled(List)<ListProps>(({ theme }) => ({
    marginBottom: 60,
    '.MuiListSubheader-root': {
      fontSize: 16,
      background: 'none',
      fontWeight: 600,
      color: COLORS.FormLabel,
      paddingLeft: 0
    },
    '.MuiListItem-root': {
      boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.12)',
      border: '1px solid #EFF4FF',
      borderRadius: 12,
      marginBottom: 14,
      position: 'relative',
      zIndex: 99,
    },
    '.MuiListItemText-primary': {
      fontSize: 16,
      fontWeight: 500,
      color: COLORS.Text,
      paddingBottom: 8,
    },
    '.MuiListItemText-secondary': {
      fontSize: 14,
      fontWeight: 400,
      color: COLORS.Gray600,
    },
    '.MuiListItemAvatar-root': {
      minWidth: 40,
    }
  }));

  useEffect(() => {
    dispatch(getDocumentList(user?.patientId))
      .unwrap()
      .then((response: any) => {
        if (response.status === 200) {
          setFilesData(response?.data?.documentInfos);
        }
      });
  }, [dispatch, location.key, user?.patientId]);

  const handleDownloadDocument = async(documentName:string) => {
    setLoader(true);
    await dispatch(downloadDocument(user?.patientId))
      .unwrap()
      .then((response: any) => {
        if (response.status === 200) {
          const linkSource =
            // eslint-disable-next-line max-len
            `data:application/pdf;base64,${response.data}`;
          const downloadLink = document.createElement('a');
          const fileName = `${documentName}.pdf`;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        } else {
          const message = 'Unable to download please try later.';
          toast(message, {
            type: 'info',
            icon: false,
          });
        }
      });
    setLoader(false);
  };

  return (
    <>
      {storeResponse?.loaders?.isDocumentsListFetched ? < Loader /> :
        <Page title='Documents' backDir={ROUTE.DASHBOARD}>
          {filesData && filesData !== null ?
            <>
              <StyleList
                subheader={
                  <ListSubheader>
                    SIGNED ({filesData?.filter((items: { status: string; }) =>
                      items.status === 'Completed').length}/{filesData?.length})
                  </ListSubheader>
                }
              >
                {filesData?.map(
                  (item: { status: string; id: React.Key | null | undefined; documentName: string; type: string; }) =>
                    item?.status === 'Completed' && (
                      <ListItem sx={{ background: '#F6FEF9' }}
                        key={item.id}
                        secondaryAction={
                          <Stack direction='row' spacing={2.5}>
                            <IconButton edge='end' aria-label='download' 
                              onClick={() => handleDownloadDocument(item.documentName)}>
                              {item?.type === 'pdf' && 
                                !loader ? <FileDownloadOutlinedIcon sx={{ ':hover': { color: COLORS.Link } }} /> :
                                <CircularProgress size={20}/>
                              } 
                            </IconButton>
                            <IconButton disabled edge='end' aria-label='download'>
                              {item?.status === 'Completed' &&
                                <TaskAltOutlinedIcon sx={{ color: COLORS.Success500 }} />}
                            </IconButton>
                          </Stack>
                        }
                      >
                        <ListItemAvatar>
                          <FileCopyOutlinedIcon sx={{ color: COLORS.Link }} />
                        </ListItemAvatar>
                        <ListItemText primary={item.documentName} secondary={`${item.type.toUpperCase()} `} />
                      </ListItem>
                    )
                )}
              </StyleList>

              <StyleList subheader={<ListSubheader >UNSIGNED</ListSubheader>}>
                {filesData?.map(
                  (item: { status: string; id: React.Key | null | undefined; documentName: string; type: string; }) =>
                    item?.status !== 'Completed' && (
                      <ListItem sx={{ background: '#FFF' }}
                        key={item.id}

                      >
                        <ListItemAvatar>
                          <FileCopyOutlinedIcon sx={{ color: COLORS.Link }} />
                        </ListItemAvatar>
                        <ListItemText primary={item.documentName} secondary={`${item.type.toUpperCase()}`} />
                      </ListItem>
                    )
                )}
              </StyleList>
            </>
            :
            'No documents are available to show'
          }

        </Page>
      }
    </>
  );
};

export default Documents;
