import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getRegistraTionDetailsAPI, getUserDetailsAPI, getUserPermissionsAPI } from 'services/authServices';
import { IUserDetails, IProviderDetails, IDeleteGuardian } from '../interfaces/IUserDetails';
import {
  addPatientDetailsAPI,
  deleteGuardianDetailsAPI,
  getCurrentTreatmentPlanAPI,
  getGuardianDetailsAPI,
  getInsuranceDetailsAPI,
  getPatientDetailsAPI,
  getPatientDetailsFromAdminAPI,
  getPharmacyDetailsAPI,
  getProfileStatusAPI,
  getProviderDetailsAPI,
  saveInsuranceDetailsAPI,
  savePharmacyDetailsAPI,
  udpatePatientDetailsAPI,
  updateGuradianDetailsAPI,
  updateProviderDetailsAPI,
  getCityStateAPI,
  updatePinAPI,
  checkPinAPI,
  getSiblingAPI,
  getSiblingForAdminAPI,
  deleteSiblingAPI
} from 'services/profileServices';
import { handleMessage } from 'shared/api/ErrorMessages';

interface Error {
  data: any;
}

export const getUserDetails = createAsyncThunk('USER_DETAILS', async (userId: IUserDetails, { rejectWithValue }) => {
  try {
    const response = await getUserDetailsAPI(userId);
    return response;
  } catch (err: any) {
    const e = err as Error;
    const status = e?.data?.errorCode;
    const message = handleMessage(status);
    const error = {
      err: err.data,
      message,
      show: false,
    };
    return rejectWithValue(error);
  }
});

export const getPatientDetails = createAsyncThunk(
  'GET_PATIENT_DETAILS',
  async (patientId: number, { rejectWithValue }) => {
    try {
      const response = await getPatientDetailsAPI(patientId);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const addPatientDetails = createAsyncThunk(
  'ADD_PATIENT_DETAILS',
  async (payload: any, { rejectWithValue }: any) => {
    try {
      const response = await addPatientDetailsAPI(payload);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const updatePatientDetails = createAsyncThunk(
  'UPDATE_PATIENT_DETAILS',
  async (payload: any, { rejectWithValue }: any) => {
    try {
      const response = await udpatePatientDetailsAPI(payload);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const getGuardianDetails = createAsyncThunk(
  'GET_GUARDIAN_DETAILS',
  async (userId: IUserDetails, { rejectWithValue }) => {
    try {
      const response = await getGuardianDetailsAPI(userId);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);
export const getProviderDetails = createAsyncThunk(
  'GET_PROVIDER_DETAILS',
  async (userId: IUserDetails, { rejectWithValue }) => {
    try {
      const response = await getProviderDetailsAPI(userId);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const updateGuardianDetails = createAsyncThunk(
  'UPDATE_GUARDIAN_DETAILS',
  async (data: any, { rejectWithValue }) => {
    const { param, payload }: any = data;
    try {
      const response = await updateGuradianDetailsAPI(param, payload);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const updateProviderDetails = createAsyncThunk(
  'UPDATE_PROVIDER_DETAILS',
  async (data: IProviderDetails, { rejectWithValue }: any) => {
    try {
      const response = await updateProviderDetailsAPI(data.userId, data.payload);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const deleteGuardianDetails = createAsyncThunk(
  'DELETE_GUARDIAN_DETAILS',
  async (data: IDeleteGuardian, { rejectWithValue }: any) => {
    try {
      const response = await deleteGuardianDetailsAPI(data);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const getPharmacyDetails = createAsyncThunk(
  'GET_PHARMACY_DETAILS',
  async (patientId: any, { rejectWithValue }) => {
    try {
      const response = await getPharmacyDetailsAPI(patientId);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const savePharmacyDetails = createAsyncThunk('SAVE_PHARMACY_DETAILS', async (data: any, { rejectWithValue }) => {
  try {
    const response = await savePharmacyDetailsAPI(data?.patientId, data?.payload);
    return response;
  } catch (err: any) {
    const e = err as Error;
    const status = e?.data?.errorCode;
    const message = handleMessage(status);
    const error = {
      err: err.data,
      message,
      show: false,
    };
    return rejectWithValue(error);
  }
});

export const getInsuranceDetails = createAsyncThunk('GET_INSURANCE_DETAILS', async (id: any, { rejectWithValue }) => {
  try {
    const response = await getInsuranceDetailsAPI(id);
    return response;
  } catch (err: any) {
    const e = err as Error;
    const status = e?.data?.errorCode;
    const message = handleMessage(status);
    const error = {
      err: err.data,
      message,
      show: false,
    };
    return rejectWithValue(error);
  }
});

export const saveInsuranceDetails = createAsyncThunk(
  'SAVE_INSURANCE_DETAILS',
  async (data: any, { rejectWithValue }) => {
    const id = data.id;
    delete data.id;
    try {
      const response = await saveInsuranceDetailsAPI(id, data);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const getRegistrationDetails = createAsyncThunk(
  'GET_REGISTRATON_DETAILS',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await getRegistraTionDetailsAPI();
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const getProfileStatus = createAsyncThunk(
  'GET_PROFILE_STATUS', async (patientId: number, { rejectWithValue }) => {
    try {
      const response = await getProfileStatusAPI(patientId);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  });

export const getPatientDetailsFromAdmin = createAsyncThunk(
  'GET_PATIENT_DETAILS_FROM_ADMIN',
  async (patientId: any, { rejectWithValue }) => {
    try {
      const response = await getPatientDetailsFromAdminAPI(patientId);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const getUserPermissions = createAsyncThunk('GET_USER_PERMISSIONS', async (data: any, { rejectWithValue }) => {
  try {
    const response = await getUserPermissionsAPI();
    return response;
  } catch (err: any) {
    const e = err as Error;
    const status = e?.data?.errorCode;
    const message = handleMessage(status);
    const error = {
      err: err.data,
      message,
      show: false,
    };
    return rejectWithValue(error);
  }
});

export const getCurrentTreatmentPlan = createAsyncThunk(
  'GET_CURRENT_PLAN', async (patientId: number, { rejectWithValue }) => {
    try {
      const response = await getCurrentTreatmentPlanAPI(patientId);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  });

export const getCityState = createAsyncThunk('GET_CITY_STATE', async (zipcode: any, { rejectWithValue }) => {
  try {
    const response = await getCityStateAPI(zipcode);
    return response;
  } catch (err: any) {
    const e = err as Error;
    const status = e?.data?.errorCode;
    const message = handleMessage(status);
    const error = {
      err: err.data,
      message,
      show: false,
    };
    return rejectWithValue(error);
  }
});
export const updatePin = createAsyncThunk(
  'UPDATE_PIN', async (data: any, { rejectWithValue }) => {
    try {
      const response = await updatePinAPI(data);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  });

export const checkPin = createAsyncThunk(
  'CHECK_PIN', async (data:any, { rejectWithValue }) => {
    try {
      const response = await checkPinAPI(data);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  });


export const getSibling = createAsyncThunk(
  'GET_SIBLING', async (data:any, { rejectWithValue }) => {
    try {
      const response = await getSiblingAPI();
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  });
  
export const getSiblingForAdmin = createAsyncThunk(
  'GET_SIBLING_ADMIN', async (userId:number, { rejectWithValue }) => {
    try {
      const response = await getSiblingForAdminAPI(userId);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  });
  
export const deleteSibling = createAsyncThunk(
  'GET_SIBLING', async (id:any, { rejectWithValue }) => {
    try {
      const response = await deleteSiblingAPI(id);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  });
    

interface Loader {
  isUserDetailsFetched: boolean;
  isProfileDetailsFetched: boolean;
  isProfileStatusFetched: boolean;
  isPermissionsFetched: boolean;
}

interface StateI {
  profileStatus: any;
  loaders: Loader;
  guardians: GuardianI[];
  error: undefined;
}

interface GuardianI {
  id: number;
  firstName: string;
  lastName: string;
  relation: string;
  mobileNumber: string;
  email: string;
  guardianCategory: string;
  isEmergency: boolean;
}

const initialState = {
  loaders: {
    isUserDetailsFetched: false,
    isProfileDetailsFetched: false,
    isProfileStatusFetched: false,
    isPermissionsFetched: false,
  },
  guardians: [],
  profileStatus: {},
  error: undefined,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearError(state: StateI) {
      state.error = undefined;
    },
    setGuardians(state, action) {
      state.guardians = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserDetails.pending, (state: StateI) => {
      state.loaders.isUserDetailsFetched = true;
    });
    builder.addCase(getUserDetails.fulfilled, (state: StateI) => {
      state.loaders.isUserDetailsFetched = false;
    });
    builder.addCase(getUserDetails.rejected, (state: StateI) => {
      state.loaders.isUserDetailsFetched = false;
    });
    builder.addCase(getPatientDetails.pending, (state: StateI) => {
      state.loaders.isProfileDetailsFetched = true;
    });
    builder.addCase(getPatientDetails.fulfilled, (state: StateI) => {
      state.loaders.isProfileDetailsFetched = false;
    });
    builder.addCase(getPatientDetails.rejected, (state: StateI) => {
      state.loaders.isProfileDetailsFetched = false;
    });
    builder.addCase(addPatientDetails.pending, (state: StateI) => {
      state.loaders.isProfileDetailsFetched = true;
    });
    builder.addCase(addPatientDetails.fulfilled, (state: StateI) => {
      state.loaders.isProfileDetailsFetched = false;
    });
    builder.addCase(addPatientDetails.rejected, (state: StateI) => {
      state.loaders.isProfileDetailsFetched = false;
    });
    builder.addCase(updatePatientDetails.pending, (state: StateI) => {
      state.loaders.isProfileDetailsFetched = true;
    });
    builder.addCase(updatePatientDetails.fulfilled, (state: StateI) => {
      state.loaders.isProfileDetailsFetched = false;
    });
    builder.addCase(updatePatientDetails.rejected, (state: StateI) => {
      state.loaders.isProfileDetailsFetched = false;
    });
    builder.addCase(getProviderDetails.pending, (state: StateI) => {
      state.loaders.isProfileDetailsFetched = true;
    });
    builder.addCase(getProviderDetails.fulfilled, (state: StateI) => {
      state.loaders.isProfileDetailsFetched = false;
    });
    builder.addCase(getProviderDetails.rejected, (state: StateI) => {
      state.loaders.isProfileDetailsFetched = false;
    });
    builder.addCase(updateProviderDetails.pending, (state: StateI) => {
      state.loaders.isProfileDetailsFetched = true;
    });
    builder.addCase(updateProviderDetails.fulfilled, (state: StateI) => {
      state.loaders.isProfileDetailsFetched = false;
    });
    builder.addCase(updateProviderDetails.rejected, (state: StateI) => {
      state.loaders.isProfileDetailsFetched = false;
    });
    builder.addCase(getGuardianDetails.pending, (state: StateI) => {
      state.loaders.isProfileDetailsFetched = true;
    });
    builder.addCase(getGuardianDetails.fulfilled, (state: StateI, action: any) => {
      state.loaders.isProfileDetailsFetched = false;
      state.guardians = action?.payload?.data;
    });
    builder.addCase(getGuardianDetails.rejected, (state: StateI) => {
      state.loaders.isProfileDetailsFetched = false;
    });
    builder.addCase(updateGuardianDetails.pending, (state: StateI) => {
      state.loaders.isProfileDetailsFetched = true;
    });
    builder.addCase(updateGuardianDetails.fulfilled, (state: StateI, action: any) => {
      state.loaders.isProfileDetailsFetched = false;
      state.guardians = action?.payload?.data;
    });
    builder.addCase(updateGuardianDetails.rejected, (state: StateI) => {
      state.loaders.isProfileDetailsFetched = false;
    });
    builder.addCase(getPharmacyDetails.pending, (state: StateI) => {
      state.loaders.isProfileDetailsFetched = true;
    });
    builder.addCase(getPharmacyDetails.fulfilled, (state: StateI, action: any) => {
      state.loaders.isProfileDetailsFetched = false;
    });
    builder.addCase(getPharmacyDetails.rejected, (state: StateI) => {
      state.loaders.isProfileDetailsFetched = false;
    });
    builder.addCase(savePharmacyDetails.pending, (state: StateI) => {
      state.loaders.isProfileDetailsFetched = true;
    });
    builder.addCase(savePharmacyDetails.fulfilled, (state: StateI, action: any) => {
      state.loaders.isProfileDetailsFetched = false;
    });
    builder.addCase(savePharmacyDetails.rejected, (state: StateI) => {
      state.loaders.isProfileDetailsFetched = false;
    });
    builder.addCase(getInsuranceDetails.pending, (state: StateI) => {
      state.loaders.isProfileDetailsFetched = true;
    });
    builder.addCase(getInsuranceDetails.fulfilled, (state: StateI, action: any) => {
      state.loaders.isProfileDetailsFetched = false;
    });
    builder.addCase(getInsuranceDetails.rejected, (state: StateI) => {
      state.loaders.isProfileDetailsFetched = false;
    });
    builder.addCase(saveInsuranceDetails.pending, (state: StateI) => {
      state.loaders.isProfileDetailsFetched = true;
    });
    builder.addCase(saveInsuranceDetails.fulfilled, (state: StateI, action: any) => {
      state.loaders.isProfileDetailsFetched = false;
    });
    builder.addCase(saveInsuranceDetails.rejected, (state: StateI) => {
      state.loaders.isProfileDetailsFetched = false;
    });
    builder.addCase(getProfileStatus.pending, (state: StateI) => {
      state.loaders.isProfileStatusFetched = true;
    });
    builder.addCase(getProfileStatus.fulfilled, (state: StateI, action: any) => {
      state.loaders.isProfileStatusFetched = false;
      state.profileStatus = action?.payload?.data;
    });
    builder.addCase(getProfileStatus.rejected, (state: StateI) => {
      state.loaders.isProfileStatusFetched = false;
    });
    builder.addCase(getUserPermissions.pending, (state: StateI) => {
      state.loaders.isPermissionsFetched = true;
    });
    builder.addCase(getUserPermissions.fulfilled, (state: StateI, action: any) => {
      state.loaders.isPermissionsFetched = false;
    });
    builder.addCase(getUserPermissions.rejected, (state: StateI) => {
      state.loaders.isPermissionsFetched = false;
    });
  },
});

export default userSlice.reducer;
export const { setGuardians } = userSlice.actions;
