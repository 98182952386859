import { API_URL } from 'shared/api/Constants';
import { apiInterceptor } from './axios';
import axios from 'axios';
import { IDeleteGuardian } from 'store/interfaces/IUserDetails';

export const updateProviderDetailsAPI = (param: number, data: any) => {
  return apiInterceptor.put(`${API_URL.PROVIDER_DETAILS}${param}`, data).then((response: any) => {
    return response;
  });
};

export const getProviderDetailsAPI = (params: any) => {
  return apiInterceptor.get(`${API_URL.PROVIDER_DETAILS}${params.userId}`).then((response: any) => {
    return response;
  });
};

export const getPatientDetailsAPI = (patientId:number) => {
  return apiInterceptor.get(`${API_URL.GET_PATIENT}/${patientId}`).then((response: any) => {
    return response;
  });
};

export const addPatientDetailsAPI = (data: any) => {
  return apiInterceptor.post(`${API_URL.GET_PATIENT}`, data).then((response: any) => {
    return response;
  });
};

export const udpatePatientDetailsAPI = (data: any) => {
  return apiInterceptor.put(`${API_URL.GET_PATIENT}`, data).then((response: any) => {
    return response;
  });
};

export const getGuardianDetailsAPI = (params: any) => {
  return apiInterceptor.get(`${API_URL.GUARDIAN_DETAILS}${params.userId}`).then((response: any) => {
    return response;
  });
};

export const updateGuradianDetailsAPI = (param: any, data: any) => {
  return apiInterceptor.put(`${API_URL.GUARDIAN_DETAILS}${param.userId}`, data).then((response: any) => {
    return response;
  });
};

export const deleteGuardianDetailsAPI = (params: IDeleteGuardian) => {
  return apiInterceptor
    .delete(`${API_URL.GUARDIAN_DETAILS}${params.patientId}/${params.guardianId}`)
    .then((response) => {
      return response;
    });
};

export const getPharmacyDetailsAPI = (patientId: any) => {
  return apiInterceptor.get(`${API_URL.PHARMACY_DETAILS}${patientId}`).then((response: any) => {
    return response;
  });
};

export const savePharmacyDetailsAPI = (patientId: number, data: any) => {
  return apiInterceptor.put(`${API_URL.PHARMACY_DETAILS}${patientId}`, data).then((response: any) => {
    return response;
  });
};

export const getInsuranceDetailsAPI = (id: number) => {
  return apiInterceptor.get(`${API_URL.INSURANCE_DETAILS}${id}`).then((response: any) => {
    return response;
  });
};

export const saveInsuranceDetailsAPI = (id: any, data: any) => {
  return apiInterceptor.put(`${API_URL.INSURANCE_DETAILS}${id}`, data).then((response: any) => {
    return response;
  });
};

export const uploadCardImageAPI = (data: any, fileData: any) => {
  const actualtoken = sessionStorage.getItem('access-token');
  const token = actualtoken?.slice(1, -1);
  const header = {
    Authorization: `Bearer ${token}`,
    'Content-type': 'multipart/form-data',
    Connection: 'keep-alive',
    'Accept-Encoding': 'gzip, deflate, br',
    Accept: '*/*',
  };
  return axios
    .post(`${API_URL.FILE_UPLOAD}${data.patientId}/${data.imageSide}/${data.insuranceType}`, fileData, {
      headers: header,
    })
    .then((response: any) => {
      return response;
    });
};

export const downloadCardImageAPI = (data: any) => {
  const actualtoken = sessionStorage.getItem('access-token');
  const token =  actualtoken?.slice(1, -1);
  const header = {
    Authorization: `Bearer ${token}`,
    Accept: '*/*',
  };
  return axios
    .get(`${API_URL.FILE_UPLOAD}${data.patientId}/${data.imageSide}/${data.insuranceType}`, { headers: header })
    .then((response: any) => {
      return response;
    });
};

export const getProfileStatusAPI = (patientId:number) => {
  return apiInterceptor.get(`${API_URL.PROFILE_STATUS}/${patientId}`).then((response: any) => {
    return response;
  });
};

export const getPatientDetailsFromAdminAPI = (patientId: number) => {
  return apiInterceptor.get(`${API_URL.PATIENT_DETAILS_FOR_ADMIN}/${patientId}`).then((response: any) => {
    return response;
  });
};

export const getCurrentTreatmentPlanAPI = (patientId:number) => {
  return apiInterceptor.get(`${API_URL.DR_CHRONO_CHART_DETAILS}/${patientId}`).then((response: any) => {
    return response;
  });
};


export const uploadProfileImageAPI = (fileData: any, patientId:number) => {
  const actualtoken = sessionStorage.getItem('access-token');
  const token = actualtoken?.slice(1, -1);
  const header = {
    Authorization: `Bearer ${token}`,
    'Content-type': 'multipart/form-data',
    // Connection: 'keep-alive',
    // 'Accept-Encoding': 'gzip, deflate, br',
    Accept: '*/*',
  };
  return axios
    .put(`${API_URL.PROFILE_IMAGE}/${patientId}`, fileData, {
      headers: header,
    })
    .then((response: any) => {
      return response;
    });
};

export const getProfileImageAPI = (patientId:any) => {
  const actualtoken = sessionStorage.getItem('access-token');
  const token =  actualtoken?.slice(1, -1);
  const header = {
    Authorization: `Bearer ${token}`,
    Accept: '*/*',
  };
  return axios
    .get(`${API_URL.PROFILE_IMAGE}/${patientId}`, { headers: header })
    .then((response: any) => {
      return response;
    });
};

export const uploadGuradianImageAPI = (fileData: any, patientId:number, guardianId:number) => {
  const actualtoken = sessionStorage.getItem('access-token');
  const token = actualtoken?.slice(1, -1);
  const header = {
    Authorization: `Bearer ${token}`,
    'Content-type': 'multipart/form-data',
    // Connection: 'keep-alive',
    // 'Accept-Encoding': 'gzip, deflate, br',
    Accept: '*/*',
  };
  return axios
    .put(`${API_URL.GUARDIAN_IMAGE}/${patientId}/${guardianId}`, fileData, {
      headers: header,
    })
    .then((response: any) => {
      return response;
    });
};


export const getCityStateAPI = (zipcode:number) => {
  return apiInterceptor.get(`${API_URL.GET_CITY_STATE}/${zipcode}`).then((response: any) => {
    return response;
  });
};

export const updatePinAPI=(data:any)=>{
  return apiInterceptor.post(`${API_URL.UPDATE_PIN}`, data).then((response:any)=>{
    return response;
  });
};

export const checkPinAPI=(data:any)=>{
  return apiInterceptor.post(`${API_URL.CHECK_PIN}`,data).then((response:any)=>{
    return response;
  });
};

export const getSiblingAPI = () => {
  return apiInterceptor.get(`${API_URL.GET_SIBLING}`).then((response: any) => {
    return response;
  });
};
  
export const getSiblingForAdminAPI = (userId:number) => {
  return apiInterceptor.get(`${API_URL.GET_SIBLING_ADMIN}/${userId}`).then((response: any) => {
    return response;
  });
};
export const deleteSiblingAPI = (id:any) => {
  return apiInterceptor.get(`${API_URL.GET_SIBLING}/${id}`).then((response: any) => {
    return response;
  });
};