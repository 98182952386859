import { apiInterceptor } from './axios';
import { API_URL } from 'shared/api/Constants';

export const getDocumentListAPI = (patientId:number) => {
  return apiInterceptor
    .get(`${API_URL.DOCUMENT_LIST}/${patientId}`)
    .then((response: any) => {
      return response;
    });
};


export const downloadDocumentAPI = (patientId:number) => {
  return apiInterceptor
    .get(`${API_URL.DOWNLOAD_DOCUMENT}/${patientId}`)
    .then((response: any) => {
      return response;
    });
};
