import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getDocumentListAPI, downloadDocumentAPI } from 'services/documentServices';
import { handleMessage } from 'shared/api/ErrorMessages';

interface Error {
  data: any;
}

export const getDocumentList = createAsyncThunk(
  'GET_DOCUMENT_LIST',
  async (patientId: number, { rejectWithValue }) => {
    try {
      const response = await getDocumentListAPI(patientId);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const downloadDocument = createAsyncThunk(
  'DOWNLOAD_DOCUMENT',
  async (patientId: number, { rejectWithValue }) => {
    try {
      const response = await downloadDocumentAPI(patientId);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);


interface Loader {
  isDocumentsListFetched: boolean;
  isRejected: boolean;
}


interface StateI {
  loaders: Loader;
  error: undefined;
}

const initialState = {
  loaders: {
    isDocumentsListFetched: false,
    isRejected: false
  },
  error: undefined,
};

const documentListSlice = createSlice({
  name: 'documentlist',
  initialState,
  reducers: {
    clearError(state: StateI) {
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDocumentList.pending, (state: StateI) => {
      state.loaders.isDocumentsListFetched = true;
    });
    builder.addCase(getDocumentList.fulfilled, (state: StateI) => {
      state.loaders.isDocumentsListFetched = false;
    });
    builder.addCase(getDocumentList.rejected, (state: StateI) => {
      state.loaders.isDocumentsListFetched = false;
    });
  },
});

export default documentListSlice.reducer;