import React from 'react';
import ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { ToastContainer, Slide } from 'react-toastify';

import App from './App';
import theme from 'theme/index';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { onSuccess, onUpdate } from './registrationStatus';
import { Provider } from 'react-redux';
import { store } from 'store/store';
import { UIKitSettingsBuilder } from '@cometchat/uikit-shared';
import { CometChatUIKit } from '@cometchat/chat-uikit-react';
import { appId, authKey } from 'shared/content/constants';

console.log('env',process.env);

(async () => {
  const region = 'US';
  const settings = new UIKitSettingsBuilder()
    .setAppId(appId)
    .setRegion(region)
    .setAuthKey(authKey)
    .build();

  CometChatUIKit.init(settings)?.then((user:any) => {
    console.log('comet chat init');
  }).catch((e:any) =>{
    console.log('cometchat error', e);
  }); 
})();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <>
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <Provider store={store}>
        <App />
        <ToastContainer
          transition={Slide}
          position='top-center'
          autoClose={5000}
          hideProgressBar={true}
          closeOnClick={true}
          pauseOnHover={true}
          draggable={true}
          limit={1}
          style={{ width: 'auto' }}
        />
      </Provider>
    </ThemeProvider>
  </>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate,
  onSuccess,
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
