import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Container, Box, Grid, Typography, CardMedia, styled, CardProps,
  Card, CardContent
} from '@mui/material';

import { getPatientDetails } from 'store/slices/userSlice';
import { useAppDispatch } from 'store/hooks';
import { AuthContext } from 'utils/AuthContext';
import { ROUTE } from 'route/RouteEnums';
import COLORS from 'theme/colors';

interface CustomizedState {
  wellbeingStatus: any;
}
const WellBeing = () => {
  const { user } = useContext(AuthContext);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [parentName, setParentName] = useState<string>();
  const [childName, setChildName] = useState<string>();
  const location = useLocation();
  const state = location.state as CustomizedState;
  const {
    wellbeingStatus
  } = state || {};

  useEffect(() => {
    dispatch(getPatientDetails(user?.patientId))
      .unwrap()
      .then((response: any) => {
        if (response.status === 200) {
          setChildName(response?.data?.firstName);
          setParentName(user?.firstName);
        }
      });
  }, [dispatch, user]);

  const handleBoxClick = (val: string) => {
    navigate(`${ROUTE.WELLBEING_ASSESSMENT}`, { state: { wellbeingUser: val } });
  };

  const StyleCard = styled(Card)<CardProps>(({ theme }) => ({
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.25)',
    borderRadius: 12,
    background: COLORS.White,
  }));



  return (
    <Container component={'main'} maxWidth='xl'>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        paddingTop={2}
        paddingBottom={2}
        maxWidth={'xl'}
      >
        <CardMedia component={'img'} src='icon-96x96.png' alt='mindweal logo' sx={{ width: 'auto', height: 56 }} />
        <Typography variant='h2' paddingBottom={8.5}>
          MindWeal Wellness Questionnaire
        </Typography>
        <Typography variant='h3' paddingBottom={4}>
          Who is answering?
        </Typography>
        <Grid container spacing={4} maxWidth={'sm'}>
          <Grid item xs={12} sm={6}>
            <StyleCard>
              <Box onClick={() => wellbeingStatus?.parentStatus === 'PENDING' ||
                wellbeingStatus?.parentStatus === 'NEW' ? handleBoxClick('parent') : null}
              sx={{ opacity: wellbeingStatus?.parentStatus !== 'COMPLETED' ? 1 : 0.5 }}
              >
                <CardMedia
                  component={'img'}
                  sx={{ height: 'auto', width: '100%', 
                    cursor: wellbeingStatus?.parentStatus !== 'COMPLETED' ? 'pointer' : 'default' }}
                  src='images/mindwealRectangleMask.png'
                  alt='Mindweal User Card'
                />
                <CardContent>
                  <Typography variant='h2' fontSize={20} py={2}
                    sx={{cursor: wellbeingStatus?.parentStatus !== 'COMPLETED' ? 'pointer' : 'default'}}>
                    {parentName}
                  </Typography>
                </CardContent>
              </Box>
            </StyleCard>
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyleCard>
              <Box onClick={() => wellbeingStatus?.childStatus === 'PENDING' ||
                wellbeingStatus?.childStatus === 'NEW' ? handleBoxClick('child') : null}
              sx={{ opacity: wellbeingStatus?.childStatus !== 'COMPLETED' ? 1 : 0.5 }}  >
                <CardMedia
                  component={'img'}
                  sx={{ height: 'auto', width: '100%',
                    cursor: wellbeingStatus?.childStatus !== 'COMPLETED' ? 'pointer' : 'default' }}
                  src='images/mindwealRectangleMask.png'
                  alt='Mindweal User Card'
                />
                <CardContent>
                  <Typography variant='h2' fontSize={20} py={2} 
                    sx={{cursor: wellbeingStatus?.childStatus !== 'COMPLETED' ? 'pointer' : 'default'}}>
                    {childName}
                  </Typography>
                </CardContent>
              </Box>
            </StyleCard>
          </Grid>
        </Grid>
      </Box>
      <Box
        component={'img'}
        src='images/mindwealWellbeingDesk.png'
        alt='wellbeing'
        height={'auto'}
        width={'100%'}
        maxWidth={600}
        sx={{ display: { xs: 'none', sm: 'block' } }}
      />
      <Box
        component={'img'}
        src='images/mindwealWellbeingMob.png'
        alt='wellbeing'
        height={'auto'}
        width={'100%'}
        maxWidth={600}
        sx={{ display: { xs: 'block', sm: 'none' } }}
        paddingTop={10}
      />
    </Container >
  );
};

export default WellBeing;
