import { apiInterceptor } from './axios';
import { API_URL } from 'shared/api/Constants';


export const saveMcatAnswersAPI = (data: any) => {
  return apiInterceptor
    .post(`${API_URL.SAVE_MCAT_ANSWERS}/${data.patientId}`, data)
    .then((response: any) => {
      return response;
    });
};

export const getMCATStatusAPI = (patientId:number) => {
  return apiInterceptor
    .get(`${API_URL.GET_MCAT_STATUS}/${patientId}`)
    .then((response: any) => {
      return response;
    });
};

export const getWellbeingStatusAPI = (patientId:number) => {
  return apiInterceptor
    .get(`${API_URL.GET_WELLBEING_STATUS}/${patientId}`)
    .then((response: any) => {
      return response;
    });
};

export const getMFSHStatusAPI = (patientId:number) => {
  return apiInterceptor
    .get(`${API_URL.GET_MFSH_STATUS}/${patientId}`)
    .then((response: any) => {
      return response;
    });
};

export const getWellbeingTrackerDetailsAPI = (data:any) => {
  return apiInterceptor
    .get(`${API_URL.GET_WELLBEING_TRACKER}/${data.period}/${data.patientId}`)
    .then((response: any) => {
      return response;
    });
};

export const getMCATTrackerDetailsAPI = (data:any) => {
  return apiInterceptor
    .get(`${API_URL.GET_MCAT_TRACKER}/${data.period}/${data.patientId}`)
    .then((response: any) => {
      return response;
    });
};

export const getMFSHLinkAPI = (patientId:number) => {
  return apiInterceptor
    .get(`${API_URL.GET_MFSH_LINK}/${patientId}`)
    .then((response: any) => {
      return response;
    });
};

export const saveWellbeingAnswersAPI = (data: any) => {
  return apiInterceptor
    .post(`${API_URL.SAVE_WELLBEING_ANSWERS}/${data?.patientId}`, data)
    .then((response: any) => {
      return response;
    });
};

export const getMCATScoreAPI = (patientId:number) => {
  return apiInterceptor
    .get(`${API_URL.GET_MCAT_SCORE}/${patientId}`)
    .then((response: any) => {
      return response;
    });
};

export const getWellbeingScoreAPI = (patientId:number) => {
  return apiInterceptor
    .get(`${API_URL.GET_WELLBEING_SCORE}/${patientId}`)
    .then((response: any) => {
      return response;
    });
};

export const getMCATReportTilesAPI=(patientId:number)=>{
  return apiInterceptor
    .get(`${API_URL.GET_REPORT_TILES}/${patientId}`)
    .then((response: any) => {
      return response;
    });
};

export const getMCATReportDetailsAPI = (patientId:number) => {
  return apiInterceptor
    .get(`${API_URL.GET_REPORT_DETAILS}/${patientId}`)
    .then((response: any) => {
      return response;
    });
};

export const saveRatingsAPI = async (data: any) => {
  const response = await apiInterceptor
    .post(`${API_URL.SAVE_RATING}/${data.patientId}`, data);
  return response;
};

export const saveSuggestionsAPI = async (data:any) => {
  const response = await apiInterceptor
    .post(`${API_URL.SAVE_SUGGESTIONS}/${data.patientId}`, data);
  return response;
};

export const downloadMCATReportAPI = async (userId:number) => {
  const response = await apiInterceptor
    .get(`${API_URL.DOWNLOAD_MCAT_REPORT}/${userId}`);
  return response;
};
