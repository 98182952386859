import React, {useEffect, useState} from 'react';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import { Page } from 'components/Page';
import { ROUTE } from 'route/RouteEnums';
import { Grid, Typography, IconButton, CardMedia, CardContent, Card, CardProps, Box, Link } from '@mui/material';
import { Container, Stack } from '@mui/system';

import { getProfileImageAPI } from 'services/profileServices';
import PrimaryContact from '../PatientInfo/PrimaryContact';
import { useAppDispatch } from 'store/hooks';
import { getInsuranceDetails, getPatientDetailsFromAdmin } from 'store/slices/userSlice';
import { getAppointmentHistory } from 'store/slices/appointmentHistorySlice';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

const AppointmentDetails = (props: any) => {
  const patientId = useParams();
  const [profilePicture, setProfilePicture] = useState<any>(null);
  const [userDetails] = useState({
    patientId: null,
    patientName: '',
    contactNumber: '',
    mcat: null,
    mfsh: null,
    wellbeing: null,
    intakePaperwork: null,
    type: null,
    upcomingAppointment: null,
  });
  const [patientDetails, setPatientDetails] = useState({
    firstName: '',
    lastName: '',
    dateOfBirth: '',
  });
  const [upcommingAppointment, setUpcommingAppointment] = useState<any>({});
  const [pastAppointments, setPastAppointments] = useState<any>([]);
  const [insurance, setInsurance] = useState('');
  const dispatch = useAppDispatch();
  
  const StyleCard = styled(Card)<CardProps>(({ theme }) => ({
    boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.12)',
    border: '1px solid #EFF4FF',
    borderRadius: 12,
    background: '#FFF',
    margin: '12px 16px',
    position: 'relative',
    zIndex: 99,
    padding: 20,
    '& .MuiTypography-root': {
      position: 'relative',
    },
    '& .MuiCardContent-root:last-child': {
      padding: 0,
    },
    '.MuiTypography-h2': {
      fontSize: 20,
    },
    '& .Mui-selected':{
      background:'#9F84FF',
      color:'#FFF!important',
      borderRadius:'23px',
      width:'100%'
    },
    '& .MuiTab-root':{
      margin:'0 60px!important'
    }
  }));

  useEffect(() =>{
    getPatientDetailsFromAdminPortal();
    getInsuranceData();
    getAppointmentHistoryData();
    getProfileImageAPI(patientId?.id)
      .then((response: any) => {
        if (response.status === 200 && response.data) {
          setProfilePicture(response.data);
        } 
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const getAppointmentHistoryData = () => {
    dispatch(getAppointmentHistory(patientId?.id))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200 && response?.data !== '') {
          if(response?.data?.upcommingAppointment?.upcomingAppointmentDateTime){
            setUpcommingAppointment(response.data.upcommingAppointment);
          }
          setPastAppointments(response?.data?.pastAppointment);
        } else {
          const message = 'Patient has not requested appointment yet.';
          toast(message, {
            type: 'info',
            icon: false,
          });
        }
      });
  };

  const getPatientDetailsFromAdminPortal = () => {
    dispatch(getPatientDetailsFromAdmin(patientId?.id))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200) {
          setPatientDetails({
            firstName: response?.data?.firstName,
            lastName: response?.data?.lastName,
            dateOfBirth: response?.data?.dateOfBirth,
          });
        }
      });
  };

  const getInsuranceData = () => {
    dispatch(getInsuranceDetails(patientId?.id))
      .unwrap()
      .then((response: any) => {
        if (response.status === 200) {
          setInsurance(response?.data?.payOutOfPocket ? 'No' : 'Yes');
        } else {
          setInsurance('Not available');
        }
      });
  };

  const getPatientFormattedName = () => {
    if (userDetails?.patientName) return userDetails?.patientName;
    else if (patientDetails?.firstName && patientDetails?.lastName)
      return `${patientDetails.firstName} ${patientDetails.lastName}`;
    else if (patientDetails?.firstName) return patientDetails?.firstName;
    else if (patientDetails?.lastName) return patientDetails?.lastName;
    else return '';
  };

  const getAppointmentStatusColor = (appointmentStatus: any) => {
    if(appointmentStatus === 'Confirmed') {return '#1570EF';}
    else if(appointmentStatus === 'Complete') {return '#027A48';}
    else if(appointmentStatus === 'Cancelled') {return '#912018';}
    else if(appointmentStatus === 'Late Cancel') {return '#912018';}
    else if(appointmentStatus === 'Bumped') {return '#912018';}
    else if(appointmentStatus === 'No-show') {return '#912018';}
    else if(appointmentStatus === 'Scheduled') {return '#1570EF';}
    return '#1570EF';
  };

  const loadPastAppointments = () => {
    return (<Grid item xs={6} md={12} alignItems={'flex-start'} >
      <Typography variant='h2' sx={{ fontSize: 20, fontWeight: 500, lineHeight: 1.4, marginTop: '16px' }}>
        Past Appointments
      </Typography>
      {pastAppointments.map((item: any, index: number)=>{
        return <Grid key={index} item  alignItems={'flex-start'} 
          borderBottom={'1px solid #EAECF0'}>
          <Typography sx={{
            color:'#475467',
            fontFamily:'Graphik Trial',
            fontSize:'18px',
            lineHeight:'24px',
            mb:'8px',
            marginTop: '24px'
          }}>Appointment Date</Typography>
          <Stack spacing={1} direction={'column'} >
            <Stack direction='row' spacing={1} alignItems={'center'}>
              <CardMedia component={'img'} src='../../images/calendar.svg' alt='card-img1'
                sx={{ height: '20px', width: '20px', mb: '3px' }} />
              <Stack direction='row' spacing={1} alignItems={'center'}>
                <Typography sx={{color:'#2C3446', fontSize:'18px', lineHeight: '28px', fontWeight:500}}>
                  {item?.upcomingAppointmentDateTime ? dayjs(item?.upcomingAppointmentDateTime).format('MMM DD, YYYY') 
                    : ''}
                </Typography>
                <Typography sx={{color:'#2C3446', fontSize:'18px', lineHeight: '28px', fontWeight:500}}>
                  at
                </Typography>
                <Typography sx={{color:'#2C3446', fontSize:'18px', lineHeight: '28px', fontWeight:500}}>
                  {item?.upcomingAppointmentDateTime ? dayjs(item?.upcomingAppointmentDateTime).format('hh:mm A'): ''}
                </Typography>
              </Stack>
            </Stack>
          </Stack> 
          <Stack direction='row' spacing={1} alignItems={'center'}>
            <Box bgcolor={getAppointmentStatusColor(item.status)} 
              borderRadius='12px' marginTop='10px' marginBottom='16px'>
              <Typography sx={{color:'white', fontSize:'12px', fontWeight:500, paddingLeft: '12px', 
                paddingRight: '12px', paddingTop: '2px', paddingBottom: '2px'}}>
                {item.status === '' && item?.upcomingAppointmentDateTime ? 'Scheduled' : item.status}
              </Typography>
            </Box>
          </Stack>
        </Grid>;})
      }
    </Grid>);
  };

  return (
    <Page backDir={ROUTE.ADMIN_DASHBOARD} buttonText='Dashboard'>
      <Container component='main' maxWidth='md'>
        <Grid container spacing={2}>
          <Grid item xs={10} display={'flex'} alignItems={'center'}>
            <Typography variant='h5' gutterBottom>
                            Appointment History
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton>
              <CardMedia
                component={'img'}
                src={profilePicture !== null ? `data:image/png;base64, ${profilePicture}` : '/images/Group 9513.png'}
                alt='patient picture'
                sx={{
                  border: '1px solid #333',
                  borderRadius: '50%',
                  height: { xs: '70px', md: '106px' },
                  width: { xs: '70px', md: '106px' },
                  cursor: 'default'
                }}
              />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <StyleCard sx={{ borderTop: '12px solid #FFC44D !important' }}>
              <CardContent>
                <Grid container borderBottom={'1px solid #EAECF0'}>
                  <Grid item xs={6} md={8} alignItems={'flex-start'}>
                    <Typography variant='h2' sx={{ fontSize: 20, fontWeight: 500, 
                      lineHeight: 1.4, textTransform: 'capitalize' }}>
                      {getPatientFormattedName()}
                    </Typography>
                    <Typography variant='h3' sx={{ fontSize: 14, fontWeight: 400, lineHeight: 1.4 }}>
                      {insurance !== '' ? (
                        <>Insurance : <b> {insurance}</b></>
                      ) : null}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={4} display='flex' alignItems={'flex-start'} justifyContent='end'>
                    {patientDetails?.dateOfBirth && (
                      <Typography variant='h2' sx={{ span: { fontSize: 10 }, fontSize: 20, fontWeight: 500 }}>
                        <span>DOB:</span> {dayjs(patientDetails?.dateOfBirth).format('MMM DD, YYYY')}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <PrimaryContact patientId={patientId?.id} />
              </CardContent>
            </StyleCard>
          </Grid>

          <Grid item xs={12}>
            <StyleCard>
              <CardContent>
                <Grid container>
                  <Grid item xs={6} md={12} alignItems={'flex-start'} borderBottom={'1px solid #EAECF0'} mb='4px'>
                    <Typography variant='h2' sx={{ fontSize: 20, fontWeight: 500, lineHeight: 1.4 }}>
                     Upcoming Appointment
                    </Typography>
                    {upcommingAppointment.upcomingAppointmentDateTime !== undefined ?
                      <Box sx={{
                        padding:'0px 20px',
                        borderLeft:'3px solid #FFC44D',
                        margin:'16px 0'
                      }}>
                        <Typography sx={{
                          color:'#475467',
                          fontFamily:'Graphik Trial',
                          fontSize:'18px',
                          lineHeight:'24px',
                          mb:'8px'
                        }}>Appointment Date</Typography>

                        <Stack spacing={1} direction={'column'} >
                          <Stack direction='row' spacing={1} alignItems={'center'}>
                            <CardMedia component={'img'} src='../../images/calendar-check.svg' alt='card-img1'
                              sx={{ height: '20px', width: '20px', mb: '4px' }} />
                            <Stack direction='row' spacing={1} alignItems={'center'}>
                              <Typography sx={{color:'#2C3446', fontSize:'18px', fontWeight:500}}>
                                {dayjs(upcommingAppointment?.upcomingAppointmentDateTime).format('MMM DD, YYYY')}
                              </Typography>
                              <Typography sx={{color:'#2C3446', fontSize:'18px', fontWeight:500}}>
                              at
                              </Typography>
                              <Typography sx={{color:'#2C3446', fontSize:'18px', fontWeight:500}}>
                                {dayjs(upcommingAppointment?.upcomingAppointmentDateTime).format('hh:mm A')}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>

                        <Typography sx={{
                          color:'#475467',
                          fontFamily:'Graphik Trial',
                          fontSize:'18px',
                          lineHeight:'24px',
                          mt:'12px',
                          mb: '4px'
                        }}>Provider Name</Typography>
                        <Stack spacing={1} direction={'row'} >
                          <CardMedia component={'img'} src='../../images/stethoscope.svg' alt='card-img1'
                            sx={{ height: '20px', width: '20px', mb: '3px' }} />
                          <Typography sx={{color:'#2C3446', fontSize:'18px', 
                            fontWeight: 500}}>{upcommingAppointment?.providerName || ''}</Typography>
                        </Stack>
                        <Typography sx={{
                          color:'#475467',
                          fontFamily:'Graphik Trial',
                          fontSize:'18px',
                          lineHeight:'24px',
                          mt:'12px',
                          mb: '4px'
                        }}>Type</Typography>
                        <Stack spacing={1} direction={'row'} >
                          <CardMedia component={'img'} src='../../images/type.svg' alt='card-img1'
                            sx={{ height: '20px', width: '20px', mb: '3px', objectFit: 'contain'}} />
                          <Typography sx={{color:'#344054', fontSize:'18px', fontWeight: 500, 
                            fontStyle: 'bold', lineHeight: '28px'}}> 
                            {upcommingAppointment?.evaluation ? upcommingAppointment?.evaluation : '' }
                            {upcommingAppointment?.consultationType !== null ? ', ' :''} 
                            {upcommingAppointment?.consultationType ? upcommingAppointment?.consultationType : '' } 
                          
                            {upcommingAppointment?.consultationType === 'Virtual Meeting' ? (
                              <>
                              - <Link
                                  href={upcommingAppointment?.virtualMeetingLink}
                                  fontSize={'18px !important'}
                                  target='_blank'>
                                Click Here
                                </Link>
                              </>
                            ) : null}
                          </Typography>
                        </Stack>
                        <Typography sx={{
                          color:'#475467',
                          fontFamily:'Graphik Trial',
                          fontSize:'18px',
                          lineHeight:'24px',
                          mt:'12px',
                          mb: '4px'
                        }}>Location</Typography>
                        <Stack spacing={1} direction={'row'} >
                          <CardMedia component={'img'} src='../../images/location.svg' alt='card-img1'
                            sx={{ height: '20px', width: '20px', mb: '3px', objectFit: 'contain'}} />
                          <Typography sx={{color:'#344054', fontSize:'18px', fontWeight: 500, 
                            fontStyle: 'bold', lineHeight: '28px'}}> 
                            {upcommingAppointment?.officeAddress ? upcommingAppointment?.officeAddress : '' } 
                            {upcommingAppointment?.virtualMeetingLink == null ? '' : '- Virtual'}
                          </Typography>
                        </Stack>

                        <Stack direction='row' spacing={1} alignItems={'center'}>
                          <Box bgcolor={getAppointmentStatusColor(upcommingAppointment?.status)} 
                            borderRadius='12px'  marginTop='10px'>
                            <Typography sx={{color:'white', fontSize:'12px', fontWeight:500, 
                              paddingLeft: '12px', paddingRight: '12px',
                              paddingTop: '2px', paddingBottom: '2px'}}>
                              {upcommingAppointment?.status ==='' && upcommingAppointment?.upcomingAppointmentDateTime? 
                                'Scheduled' : upcommingAppointment?.status}
                            </Typography>
                          </Box>
                        </Stack>
                      </Box> : 
                      <Box sx={{
                        padding:'0px 10px',
                        margin:'16px 0'
                      }}>
                        <Typography>
                          No Upcoming Appointments.
                        </Typography>
                      </Box>
                    } 
                  </Grid>
                  <Grid item xs={6} md={12} alignItems={'flex-start'} >
                    {pastAppointments && pastAppointments?.length > 0 && loadPastAppointments()}
                  </Grid>
                </Grid>
              </CardContent>
            </StyleCard>
          </Grid>
        </Grid>
      </Container>      
    </Page>
  );
};

export default AppointmentDetails;
