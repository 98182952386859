import React, {useState, useEffect, useContext} from 'react';
import { Grid, Typography, Container, CardMedia, Tooltip as MuiToolTip,
  Box, Button, AccordionSummary, AccordionDetails, Accordion } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts';
import { useAppDispatch } from 'store/hooks';
import { getMCATScore, getMCATTrackerDetails } from 'store/slices/mcatSlice';
import { Page } from 'components/Page';
import { ROUTE } from 'route/RouteEnums';
import { getAppointmentConfirmationDetails } from 'store/slices/appointmentSlice';
import dayjs from 'dayjs';
import { AuthContext } from 'utils/AuthContext';

const MCATiDashboard = (props: any) => {
  const { user } = useContext(AuthContext);
  const [score, setScore] = useState<any>();  
  const [category, setCategory] = useState('');
  const [trackerPeriod, setTrackerPeriod] = useState('6M');
  const [data, setData] = useState<any>();
  const [nextCheckIn, setNextCheckIn] = useState<any>();
  const [showOverAll,setShowOverAll] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(()=>{
    
    dispatch(getMCATScore(user?.patientId))
      .unwrap()
      .then(async (response: any) => {
        if (response?.status === 200) {
          const mcatScore = response?.data?.mcatIScore;
          setScore(response?.data?.mcatIScore);
          if( mcatScore < 50) { setCategory('In Crisis');}
          else if( mcatScore > 50 && mcatScore <= 100) {setCategory('Critical');}
          else if( mcatScore > 100 && mcatScore <= 225) {setCategory('Struggling');}
          else if( mcatScore > 225 && mcatScore <= 325) {setCategory('Vulnerable');}
          else if( mcatScore > 325 && mcatScore <= 400) {setCategory('Balanced');}
          else if( mcatScore > 400 && mcatScore <= 450) {setCategory('Stable');}
          else if( mcatScore > 450 && mcatScore <= 500) {setCategory('Thriving');}
        }
      });
    
  },[dispatch, user?.patientId]);

  useEffect(()=>{
    const data = {
      period:trackerPeriod,
      patientId:user?.patientId
    };
    dispatch(getMCATTrackerDetails(data))
      .unwrap()
      .then((response:any) => {
        if(response.status === 200){
          const year:any=[];
          // eslint-disable-next-line array-callback-return
          response.data.map((d:any) => {
            year.push(d.date.slice(-4));
          });
          const unique = year.filter((item:any, i:any, ar:any) => ar.indexOf(item) === i);
          if(unique.length > 1){
            setShowOverAll(true);
          }
          setData(response?.data);
        }
      });
    
    dispatch(getAppointmentConfirmationDetails(user?.patientId))
      .unwrap()
      .then((response:any) =>{
        if(response.status === 200){
          const date = new Date(response.data);
          if(new Date(date) > new Date()){
            const checkInDate = dayjs(date).format('MM/DD/YYYY hh:mm A');   
            setNextCheckIn(checkInDate);
          }
        }
      }); 
  },[dispatch, trackerPeriod, user?.patientId]);


  const handleTrackerPeriod=(period:string)=>{
    setTrackerPeriod(period);
  };

  const getMCATScoreColor = (category: string) => {
    if(category === 'In Crisis') return '#F04438';
    else if(category === 'Critical') return '#FF4405';
    else if(category === 'Struggling') return '#EF6820';
    else if(category === 'Vulnerable') return '#F79009';
    else if(category === 'Balanced') return '#EAAA08';
    else if(category === 'Stable') return '#66C61C'; 
    else return '#12B76A';
  };

  const getMCATInfo = (score: number) => {
    if(score <= 50) {
      // eslint-disable-next-line max-len
      return 'In an acute state requiring immediate action. Symptoms are incapacitating and at unprecedented levels. Immediate psychiatric intervention, such as emergency services or a crisis hotline, is critical.';
    }else if(score >= 51 && score <= 100) {
      // eslint-disable-next-line max-len
      return 'Experiencing intense, almost constant symptoms that are unmanageable and severely disrupt daily life. Urgent care from a mental health professional is necessary to address these profound impacts.';
    }else if(score >= 101 && score <= 225) {
      // eslint-disable-next-line max-len
      return 'Facing significant, challenging symptoms that frequently disrupt daily activities. Regular psychiatric and therapy sessions are recommended to manage these difficulties effectively.';
    }else if(score >= 226 && score <= 325) {
      // eslint-disable-next-line max-len
      return 'Regular, noticeable symptoms that moderately affect daily life. Continuation of regular psychiatric care and therapy is advised to help manage these persistent challenges.';
    } else if(score >= 326 && score <= 400) {
      // eslint-disable-next-line max-len
      return 'Occasionally experiencing symptoms, but they are manageable and minimally impact daily activities. Continued psychiatric or therapy sessions are beneficial to sustain balance and promote further stability.';
    } else if(score >= 401 && score <= 450) {
      // eslint-disable-next-line max-len
      return 'Experiencing minimal symptoms with negligible impact on daily life. This stage represents a well-managed state of mental health.';
    }else {
      // eslint-disable-next-line max-len
      return 'Free from psychiatric symptoms, demonstrating strong mental health resilience. This stage reflects optimal mental health.';
    }                   
  };

  return (
    <Page backDir={ROUTE.PROFILE_DASHBOARD}>
      <Container component='main' maxWidth='md'>
      
        <Grid>
          <Grid item  sx={{textAlign:'center'}} alignItems={'center'}>
          
            <Box pt='16px' display={'flex'} alignItems={'center'} flexDirection={'column'}>
            
              <CardMedia
                component={'img'}
                src='../images/mcatIScorewidget.svg'
                alt='dashboard'
                sx={{ width: 70, height: 'auto'}}    
              />
              <Typography
                variant='h5'
                position={'relative'}
                width='100%'
                sx={{ textAlign: 'center',color:'#1C1F24' }}
              >
                      MCAT-i Score
                <CardMedia
                  component={'img'}
                  src='../images/vector.svg'
                  alt='heading divider'
                  sx={{ position: 'absolute', left:{xs:'31%', sm:'41%', md:'36%'}, 
                    width: {xs:70, sm:80, md:140}, height: 'auto' }}
                />
              </Typography>
              <Typography sx={{marginTop:'15px', fontSize:'20px', color:'#1C1F24' }}>
               Inverting Psychiatric Intensity into Wellness Milestone
              </Typography>
            </Box>
          </Grid>
        </Grid>
       
        <Grid>
        
          <Box mt='30px' p='20px 14px' 
            sx={{ background: '#fff', 
              alignItems:'center', 
              borderRadius:'12px', 
              border:'1px solid #EFF4FF',
              boxShadow:'0px 4px 16px 0px rgba(0, 0, 0, 0.12)'
            }} >  
            <Grid container>
              <Grid item xs={8}>
                <Typography variant='h2' color='#1C1F24'>MCAT-i Tracker</Typography>
                <Box sx={{textAlign:'center', 
                  background: getMCATScoreColor(category),
                  width:'150px', maxWidth:'153px', borderRadius:'12px',
                  padding:'2px 12px', color:'#fff', fontSize:'14px', fontWeight:500}}>{category}</Box>
              </Grid>
              <Grid item xs={4} sx={{textAlign:'end', 
                color: getMCATScoreColor(category),
                fontSize:'24px',
                fontWeight:'bolder',
                lineHeight:'26px'
              }}>
                {score} 
                <Typography>Current Score</Typography>
              </Grid>
            </Grid>
            <Grid>
              <Box width='100%' 
                sx={{background:'#F9FAFB', 
                  display: 'flex',
                  padding: '0',
                  margin:'30px 0',
                  borderRadius: '24px',
                  border:'1px solid #F2F4F7',
                  justifyContent: 'center',
                  alignItems: 'center',
                  alignSelf:'stretch'
                }}>
                <Grid container sx={{height:'40px'}}>
                  <Grid item sx={{ padding:'1.5px 30px'}}>
                    <MuiToolTip title='Last 90 days'>
                      <Button variant={trackerPeriod === '3M' ? 'contained' : 'text'}
                        sx={{
                          color: trackerPeriod === '3M' ? '#fff' : '#667085', minWidth: {
                            xs: '45px',
                            md: showOverAll ? '130px' : '200px'
                          }, maxWidth: { xs: '45px', md: showOverAll ? '130px' : '200px' },
                          minHeight:'37px!important',
                          maxHeight:'37px!important',
                          background: trackerPeriod === '3M' ? '#9F84FF' :'none',
                          ':hover':{
                            background: '#9F84FF',
                            color:'#fff'
                          }
                        }}
                        onClick={() => handleTrackerPeriod('3M')}> 3M </Button>
                    </MuiToolTip>
                  </Grid>
                  <Grid item sx={{ padding: '1.5px 30px' }}>
                    <MuiToolTip title='Last 180 days'>
                      <Button variant={trackerPeriod === '6M' ? 'contained' : 'text'}
                        sx={{
                          color: trackerPeriod === '6M' ? '#fff' : '#667085', minWidth: {
                            xs: '45px',
                            md: showOverAll ? '130px' : '200px'
                          }, maxWidth: { xs: '45px', md: showOverAll ? '130px' : '200px' },
                          minHeight:'37px!important',
                          maxHeight:'37px!important',
                          background: trackerPeriod === '6M' ? '#9F84FF' :'none',
                          ':hover':{
                            background: '#9F84FF',
                            color:'#fff'
                          }
                        }}
                        onClick={() => handleTrackerPeriod('6M')}> 6M </Button>
                    </MuiToolTip>
                  </Grid>
                  <Grid item sx={{ padding: '1.5 30px' }}>
                    <MuiToolTip title='Last 365 days'>
                      <Button variant={trackerPeriod === '1Y' ? 'contained' : 'text'}
                        sx={{
                          color: trackerPeriod === '1Y' ? '#fff' : '#667085', minWidth: {
                            xs: '45px',
                            md: showOverAll ? '130px' : '200px'
                          }, maxWidth: { xs: '45px', md: showOverAll ? '130px' : '200px' },
                          minHeight:'37px!important',
                          maxHeight:'37px!important',
                          background: trackerPeriod === '1Y' ? '#9F84FF' :'none',
                          ':hover':{
                            background: '#9F84FF',
                            color:'#fff'
                          }
                        }}
                        onClick={() => handleTrackerPeriod('1Y')}> 1Y </Button>
                    </MuiToolTip>
                  </Grid>
                  {showOverAll &&
                    <Grid item sx={{ padding:'0 30px'}}>
                      <Button variant={trackerPeriod === 'OVERALL' ? 'contained' :'text'} 
                        sx={{color: trackerPeriod === 'OVERALL'? '#fff':'#667085', minWidth:{xs:'45px',md:'130px' }, 
                          maxWidth:{xs:'45px', md:'130px' }}} 
                        onClick={() => handleTrackerPeriod('OVERALL')}> All </Button>
                    </Grid>}
                
                </Grid>
              </Box>
              {data?.length > 1 ?      
                <ResponsiveContainer width='90%' height={200}>
                  <AreaChart width={730} height={250} data={data}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                    <defs>
                      <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
                        <stop offset='5%' stopColor='#FFC44D' stopOpacity={0.4}/>
                        <stop offset='95%' stopColor='#FFFFFF' stopOpacity={0}/>
                      </linearGradient>
                  
                    </defs>
                    <XAxis dataKey='date' />
                    <YAxis />
                    <CartesianGrid strokeDasharray='7 7' stroke='#E5E5EF' />
                    <Tooltip />
                
                    <Area type='linear' dataKey='score' stroke='#FFC44D' fillOpacity={1} fill='url(#colorUv)'  
                      dot={{stroke: '#FFC44D', fill:'#FFC44D', strokeWidth: 1, r: 3,strokeDasharray:''}}
                      strokeWidth={2}
                    />
                  </AreaChart>
                </ResponsiveContainer> :
                <Typography variant='h4' textAlign='center'>
              You have taken only 1 appointment in {trackerPeriod === '3M' ? '3 Months' :
                    trackerPeriod === '6M' ? '6 Months' : '1 Year'}
                </Typography>
              }
            </Grid>
            <Box width='100%' 
              sx={{background:'#F9FAFB', 
                display: 'flex',
                padding: '8px 0',
                borderRadius: '8px',
                justifyContent: 'center',
                alignItems: 'center',
                alignSelf:'stretch',
                mt:'32px'
              }}>
              <Grid container>
                <Grid item sx={{borderRight:'1px solid #c9cacb', padding:'0 30px', 
                  maxWidth:{xs:'33%', sm:'inherit'}, marginBottom:'10px'}}>
                  <Typography color='#F04438' sx={{fontSize:'14px', fontWeight:'600'}}>0-50</Typography>
                  <Typography color='#2C3446' sx={{fontSize:'14px', fontWeight:'400'}}>In Crisis</Typography>
                </Grid>
                <Grid item sx={{borderRight:'1px solid #c9cacb', padding:'0 30px', 
                  maxWidth:{xs:'33%', sm:'inherit'}, marginBottom:'10px'}} >
                  <Typography color='#FF4405' sx={{fontSize:'14px', fontWeight:'600'}}>51-100</Typography>
                  <Typography color='#2C3446' sx={{fontSize:'14px', fontWeight:'400'}}>Critical</Typography>
                </Grid>
                <Grid item sx={{borderRight:'1px solid #c9cacb', padding:'0 30px', 
                  maxWidth:{xs:'33%', sm:'inherit'}, marginBottom:'10px'}}>
                  <Typography color='#EF6820' sx={{fontSize:'14px', fontWeight:'600'}}>101-225</Typography>
                  <Typography color='#2C3446' sx={{fontSize:'14px', fontWeight:'400'}}>Struggling</Typography>
                </Grid>
                <Grid item sx={{borderRight:'1px solid #c9cacb', padding:'0 30px', 
                  maxWidth:{xs:'33%', sm:'inherit'}, marginBottom:'10px'}}>
                  <Typography color='#F79009' sx={{fontSize:'14px', fontWeight:'600'}}>226-325</Typography>
                  <Typography color='#2C3446' sx={{fontSize:'14px', fontWeight:'400'}}>Vulnerable</Typography>
                </Grid>
                <Grid item sx={{borderRight:'1px solid #c9cacb', padding:'0 30px', 
                  maxWidth:{xs:'33%', sm:'inherit'}, marginBottom:'10px'}}>
                  <Typography color='#EAAA08' sx={{fontSize:'14px', fontWeight:'600'}}>326-400</Typography>
                  <Typography color='#2C3446' sx={{fontSize:'14px', fontWeight:'400'}}>Balanced</Typography>
                </Grid>
                <Grid item sx={{borderRight:'1px solid #c9cacb', padding:'0 30px', 
                  maxWidth:{xs:'33%', sm:'inherit'}, marginBottom:'10px'}}>
                  <Typography color='#66C61C' sx={{fontSize:'14px', fontWeight:'600'}}>401-450</Typography>
                  <Typography color='#2C3446' sx={{fontSize:'14px', fontWeight:'400'}}>Stable</Typography>
                </Grid>
                <Grid item sx={{padding:'0 10px', maxWidth:{xs:'33%', sm:'inherit'}, marginBottom:'10px'}}>
                  <Typography color='#12B76A' sx={{fontSize:'14px', fontWeight:'600'}}>451-500</Typography>
                  <Typography color='#2C3446' sx={{fontSize:'14px', fontWeight:'400'}}>Thriving</Typography>
                </Grid>

              </Grid>
            </Box>

            <Grid mt={5}>
              <Accordion sx={{ margin:'16px 0!important', boxShadow:'none', border:'1px solid #ccc'}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon color='primary' />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                  sx={{
                    '&.Mui-expanded':{
                      background:'#F9FAFB'
                    },
                    background:'#F9FAFB', 
                    borderRadius: '0px',
                    boxShadow: 'none' 
                  }} 
                >
                  <Typography sx={{fontSize:'16px', fontWeigth:500, color: '#1C1F24'}}> 
                   What does My Current MCAT-i Score 
                    <span style={{
                      color: getMCATScoreColor(category),
                      padding:'0 0 0 4px'}}> 
                      {score} 
                    </span> mean? 
                  </Typography>
                  
                </AccordionSummary>
                <AccordionDetails>
                  <Typography color='#1C1F24' mt='24px' mb='30px' fontWeight={600}>{category}:</Typography>
                  <Typography color='#1C1F24'>
                    {getMCATInfo(score)}               
                  </Typography>
                  <Box sx={{background:'#F8F5FF', padding:'10px', margin:'15px 0', display:'flex',
                    flexDirection:'row'}}>
                    <Typography variant='body1' lineHeight={1.5} color='#1C1F24'>
                      <b>Disclaimer:</b>
                       &nbsp;The information provided here is for general understanding and does not replace 
                      professional psychiatric consultation. For personalized advice and treatment, 
                      please consult with your provider.
                    </Typography>
                      
                  </Box>
                </AccordionDetails>
              </Accordion>

               
            </Grid>
            {nextCheckIn ? <Box width='100%' 
              sx={{background:'#F9FAFB', 
                display: 'flex',
                padding: '8px 12px',
                margin:'30px 0',
                borderRadius: '8px',
                border:'1px solid #F2F4F7',
                justifyContent: 'right',
                alignItems: 'center',
                alignSelf:'stretch'
              }}>
              <Grid container>
                <Grid item xs={8}>
                  <Typography color='#1C1F24'>Next Check In</Typography>
                </Grid>

                <Grid item xs={4} textAlign={'right'}>
                  <Typography color='#1C1F24'>{nextCheckIn}</Typography>
                </Grid>
              </Grid>
            </Box> : null }
          </Box>
            
          <Grid>
            <Box width='100%' 
              sx={{background:'#FFECC8', 
                display: 'flex',
                padding: '30px',
                margin:'30px 0',
                borderRadius: '24px',
                border:'1px solid #F2F4F7',
                justifyContent: 'center',
                alignItems: 'center',
                alignSelf:'stretch'
              }}>
              <Grid container>
                <Grid item xs={3}>
                  <CardMedia
                    component={'img'}
                    src='../images/mcatIDiscover.svg'
                    alt='heading divider'
                    sx={{width:'80%'}}
                  />
                </Grid>
                <Grid item xs={9}>
                 
                  <Typography 
                    sx={{fontSize:'30px', fontFamily:'Playfair Display', 
                      lineHeight:'55px', fontWeight:'600', color:'#1C1F24'}}>Discover MCAT-i
                    <CardMedia
                      component={'img'}
                      src='../images/vector.svg'
                      alt='heading divider'
                      sx={{ position: 'absolute', left:'52%', width: 150, height: 'auto' }}
                    />
                  </Typography>   
                  <Typography mt={4} sx={{textAlign:'justify', color:'#1C1F24', fontSize:'16px'}}>
                MindWeal&apos;s unique wellness compass! It&apos;s simple: our experts assess the severity 
                of psychiatric symptoms during your visits. But here&apos;s the twist - the less intense 
                these symptoms, the higher your MCAT-i score climbs. So with every dip in intensity, 
                celebrate your rising journey to wellness with MCAT-i !!
                  </Typography>
                </Grid>
              </Grid>

            </Box>
          </Grid>
        </Grid> 

      </Container>
    </Page>
  );
};

export default MCATiDashboard;
