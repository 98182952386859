import { useEffect, useState, useContext } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { taskSchema } from 'utils/schema';
import dayjs from 'dayjs';
import { useAppDispatch } from 'store/hooks';
import { getVMANames, createTask } from 'store/slices/secureMessageSlice';
import { toast } from 'react-toastify';
import { AuthContext } from 'utils/AuthContext';

const CreateTask = (props: any) => {
  const [open, setOpen] = useState<boolean>(true);
  const [vamNames, setVMANames] = useState<any>();
  const[disableSaveButton,setDisableSaveButton] = useState(false);
  const dispatch = useAppDispatch();
  const {user} = useContext(AuthContext);
  const {
    register,
    handleSubmit,
    formState: {isValid, isDirty },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(taskSchema),
    mode: 'all',
  });

  useEffect(() => {
    if (props.editData !== null) {
      setValue('taskTitle', props?.editData[0]?.taskTitle);
      setValue('assignedTo', props?.editData[0]?.vmaName);
      setValue('category', props?.editData[0]?.category);
      setValue('dueDate', dayjs(props?.editData[0]?.dueDate).utc().format('MM/DD/YYYY'));
      setValue('notes', props?.editData[0]?.notes);
      setValue('patientName', props?.editData[0]?.patientName);
      setValue('priority', props?.editData[0]?.priority);
      setValue('status', props?.editData[0]?.status);
    } else {
      setValue('patientName', props?.patientName);
    }
  }, [props.editData, props.patientName, user.firstName, user.lastName, setValue]);

  useEffect(() => {
    // api call to get vma names
    dispatch(getVMANames({}))
      .unwrap()
      .then((res: any) => {
        if(res.status === 200){
          setVMANames(res?.data);
        }
      });

  }, [dispatch]);

  const handleClose = () => {
    setOpen(false);
    if(props?.editData?.length){
      props?.onClose();
    }
  };

  const handleDateChange = (val: any) => {
    setValue('dueDate', val, {shouldValidate:true, shouldDirty:true});
    register('dueDate');
  };

  const getVMAId=(name:any)=>{
    // eslint-disable-next-line array-callback-return
    const vmaId = vamNames.filter((v:any) => {
      if(name === v.firstName+' '+v.lastName){
        return v;
      }
    });
    return vmaId[0]?.userId;
  };

  const onSubmitForm = (formData: any) => {
    setDisableSaveButton(true);
    const data ={
      id: props?.editData !== null ? props?.editData[0]?.id : null,
      mindwealUserId: parseInt(props?.userId),
      status: formData?.status,
      taskTitle: formData?.taskTitle,
      priority: formData?.priority,
      category: formData?.category,
      assignedTo: getVMAId(formData?.assignedTo),
      dueDate: dayjs(formData?.dueDate).format('YYYY-MM-DD'),
      notes:formData?.notes,
      assignedBy: user.firstName+' '+user?.lastName,
      patientId: parseInt(props?.patientId),
    };
    dispatch(createTask(data))
      .unwrap()
      .then((res: any) => {
        if (res.status === 200) {
          toast( props?.editData !== null ? 'Task updated successfully' :'Task created successfully',{
            type:'success',
            icon:true
          });
          setTimeout(()=>{
            props.onClose();
            setDisableSaveButton(false); 
          }, 2000);  
                 
        }
      });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box component='form' width={'100%'} onSubmit={handleSubmit((formData) => onSubmitForm(formData))}>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>

          <Grid container spacing={3} >
            <Grid item xs={12}>
              <Typography variant='h2' sx={{ borderBottom: '1px solid #EAECF0' }} textAlign={'center'} pb={2}>
                { props.editData !== null ? 'Edit Task' : 'Create Task' }
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor='taskTitle'>Task Title*</InputLabel>
              <TextField id='taskTitle' variant='outlined' fullWidth
                {...register('taskTitle')}
                value={watch('taskTitle') ? watch('taskTitle') : ''}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor='assignedTo'>Assign To*</InputLabel>
              <TextField id='assignedTo' variant='outlined' fullWidth select
                {...register('assignedTo')}
                value={watch('assignedTo') ? watch('assignedTo') : ''}
              >
                {vamNames?.length && vamNames.map((v:any) =>{
                  return(
                    <MenuItem key={v.firstName+v.lastName} value={v.firstName+' '+v.lastName}>
                      {v.firstName+' '+v.lastName}</MenuItem>
                  );
                })}
                
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor='category'>Category</InputLabel>
              <TextField id='category' variant='outlined' fullWidth
                {...register('category')}
                value={watch('category') ? watch('category') : ''}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor='status'>Status*</InputLabel>
              <TextField id='status' variant='outlined' fullWidth select
                {...register('status')}
                value={watch('status') ? watch('status') : ''}
              >
                <MenuItem key='open' value='open'>Open</MenuItem>
                <MenuItem key='inprogress' value='in-progress' >In-progress</MenuItem>
                <MenuItem key='closed' value='closed'>Closed</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor='dueDate'>Due Date</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker sx={{ width: '100%' }}
                  disablePast
                  value={watch('dueDate') ? dayjs(watch('dueDate')) : null}
                  onChange={(e) => handleDateChange(e)}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor='priority*'>Priority*</InputLabel>
              <TextField id='priority*' variant='outlined' fullWidth select
                {...register('priority')}
                value={watch('priority') ? watch('priority') : ''}
              >
                <MenuItem key='low' value='low'>Low</MenuItem>
                <MenuItem key='medium' value='medium'>Medium</MenuItem>
                <MenuItem key='high' value='high'>High</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor='patientName'>Patient Name</InputLabel>
              <TextField id='patientName' variant='outlined' fullWidth
                {...register('patientName')}
                value={watch('patientName') ? watch('patientName') : ''}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor='notes'>Note</InputLabel>
              <TextField
                id='notes'
                fullWidth
                multiline
                rows={2}
                placeholder='Enter Text Here...'
                variant='filled'
                inputProps={{
                  maxLength: 255,
                }}
                {...register('notes')}
                value={watch('notes') ? watch('notes') : ''}
              />
            </Grid>
          </Grid>

        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button variant='outlined' onClick={props.onClose}>Close</Button>
          <Button variant='contained' type='submit' disabled={!isValid || !isDirty || disableSaveButton}>Save</Button>
          
        </DialogActions>
      </Box>
    </Dialog>
  );
};


export default CreateTask;